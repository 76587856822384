<template>
  <div>
    <v-row>
      <v-col cols="12" :md="previewApplication ? '6' : '3'">
        <v-list-item>
          <v-list-item-avatar color="light-blue lighten-4" size="50">
            <v-icon color="light-blue darken-4">date_range</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title> Start Date </v-list-item-title>
            <v-list-item-subtitle v-show="!isEmpty(data)">{{
              getDateFormat(data ? data.start_date : 0)
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" :md="previewApplication ? '6' : '3'">
        <v-list-item>
          <v-list-item-avatar color="cyan lighten-4" size="50">
            <v-icon color="cyan darken-4">event_busy</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title> End Date </v-list-item-title>
            <v-list-item-subtitle v-show="!isEmpty(data)">{{
              getDateFormat(data ? data.end_date : 0)
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" :md="previewApplication ? '6' : '3'">
        <v-list-item>
          <v-list-item-avatar color="orange lighten-4" size="50">
            <v-icon color="orange darken-4">schedule_send</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title> Return Date </v-list-item-title>
            <v-list-item-subtitle v-show="!isEmpty(data)">{{
              getDateFormat(data ? data.return_date : 0)
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" :md="previewApplication ? '6' : '3'">
        <v-list-item>
          <v-list-item-avatar color="green accent-2" size="50">
            <v-icon color="green darken-4">view_week</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title> Number of Days </v-list-item-title>
            <v-list-item-subtitle v-show="!isEmpty(data)">{{
              data ? data.days_applied : 0
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" :md="previewApplication ? '6' : '3'">
        <v-list-item>
          <v-list-item-avatar color="blue lighten-4" size="50">
            <v-icon color="blue darken-4">account_balance</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title> Leave Balance </v-list-item-title>
            <v-list-item-subtitle v-show="leaveBalance && leaveBalance.balance">{{ leaveBalance.balance }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "LeaveDuration",
  props: { data: Object, previewApplication: Boolean, leaveBalance: Object },
  data: () => ({}),
  methods: {
    getDateFormat: function (date) {
      return moment(date).format("Do ddd, MMM YYYY");
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
  },
};
</script>
